import { Accordion, Box, FormControl, Input, Popover, Stack, Text } from '@lego/klik-ui';
import { Date as DateIcon, StatusWarningBold as StatusWarning } from '@lego/klik-ui-icons';
import {
  differenceInDays,
  eachWeekendOfInterval,
  format,
  isBefore,
  isValid,
  startOfDay,
} from 'date-fns';
import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useRecoilState } from 'recoil';
import { appointmentDatesState } from '../utils/atoms';

const dateFormat = 'dd/MM/yyyy';

const isWeekendsIncluded = (start: Date, end: Date) => {
  if (!isValid(start) || !isValid(end)) {
    return false;
  }

  if (isBefore(end, start)) {
    return false;
  }

  return (
    eachWeekendOfInterval({
      start,
      end,
    }).length > 0
  );
};

export const AccessCardDuration: React.FC = () => {
  const [eventDates, setEventDates] = useRecoilState(appointmentDatesState);
  const [isDatepickerOpen, setDatepickerOpen] = useState(false);

  const toggleDatepicker = useCallback(() => setDatepickerOpen((prev) => !prev), []);

  const startDate = eventDates.start;
  const endDate = eventDates.end;

  const onChange = useCallback(
    (date: Date | null) => {
      console.log('onChange', date);

      if (!date) {
        return;
      }

      // initially this will be an array, other invocations of this function will just be the endDate
      setEventDates((prev) => ({
        ...prev,
        end: date,
      }));

      setDatepickerOpen(false);
    },
    [setEventDates, setDatepickerOpen]
  );

  const diff = differenceInDays(startOfDay(endDate), startOfDay(startDate)) + 1;
  const isOutOfBounds = diff > 30;

  const shouldShowWarning = !isOutOfBounds && isWeekendsIncluded(startDate, endDate);

  return (
    <Accordion.Item>
      <Accordion.Button fontSize="md" pl={0}>
        <Text flex="1">
          Access Card Duration (
          <Box as="span" color={isOutOfBounds ? 'error.400' : undefined}>{`${diff} ${
            diff === 1 ? 'day' : 'days'
          }`}</Box>
          )
        </Text>
        {/*@ts-expect-error bad typings */}
        <Accordion.Icon color="slate.900" height={6} width={6} />
      </Accordion.Button>
      <Accordion.Panel p={0}>
        <Popover closeOnBlur={true} isOpen={isDatepickerOpen}>
          <FormControl isRequired={true} my={4}>
            <FormControl.Label>Start date</FormControl.Label>
            <Text color="slate.600" fontSize="sm">
              Check-in on {format(startDate, dateFormat)}
            </Text>
          </FormControl>
          <FormControl isInvalid={isOutOfBounds} isRequired={true}>
            <FormControl.Label>End date</FormControl.Label>
            <Popover.Trigger>
              <Input.Group>
                <Input
                  isReadOnly={true}
                  onClick={toggleDatepicker}
                  value={format(endDate, dateFormat)}
                />
                <Input.RightElement cursor="pointer" onClick={toggleDatepicker}>
                  <DateIcon color="light-blue.400" fontSize="1.375rem" />
                </Input.RightElement>
              </Input.Group>
            </Popover.Trigger>
            <Popover.Content>
              <Box className={isOutOfBounds ? 'access-extended-30-days' : undefined}>
                <DatePicker
                  calendarStartDay={1}
                  endDate={endDate}
                  fixedHeight={true}
                  inline={true}
                  minDate={startDate}
                  onChange={onChange}
                  selected={startDate}
                  showWeekNumbers={
                    Office.context.platform !== Office.PlatformType.Mac
                  } /* not enough space on Mac Client to show week numbers, it will overwise overflow and cause horizontal scrolling */
                  startDate={startDate}
                />
              </Box>
            </Popover.Content>
            {shouldShowWarning && (
              <Stack direction="row" my={2}>
                <StatusWarning color="warning.400" mt={1} />
                <Text color="black" fontSize="14px" ml={1}>
                  Your visitor will not have access during weekends.
                </Text>
              </Stack>
            )}
            <Stack direction="row" my={2}>
              <StatusWarning color={isOutOfBounds ? 'error.400' : 'warning.400'} mt={1} />
              <Text color="black" fontSize="14px" ml={1}>
                Visitors are only allowed access for up to 30 days
              </Text>
            </Stack>
          </FormControl>
        </Popover>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
