import { paths } from '@vms/common';
import { atom, selector } from 'recoil';
import { isEmailExternal } from './helpers';

/**
 * This is the list of required attendees synchronized with outlook
 */
export const outlookRequiredAttendeesState = atom<Office.EmailAddressDetails[]>({
  key: 'outlookRequiredAttendeesState',
  default: [],
});

/**
 * Stores the location the user has explicitly chosen (in this case the suggestions should now be used)
 */
export const selectedLocationState = atom<string | undefined>({
  key: 'selectedLocationState',
  default: undefined,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue) {
          localStorage.setItem('lastSelectedLocation', newValue);
        }
      });
    },
  ],
});

/**
 * Stores the location we suggest based on the outlook location
 */
export const suggestedLocationState = atom<string | undefined>({
  key: 'suggestedLocationState',
  default: undefined,
});

/**
 * This is a representation of the data currently stored on the outlook item. It's used to
 * enable the "update details" button when we change anything we have attached.
 */
export const attachedDetailsState = atom<string | undefined>({
  key: 'attachedDetailsState',
  default: undefined,
});

/**
 * Aggregates selected and suggested location. The reception chosen is based
 * on a prioritized list:
 * 1. The location the user has explicitly chosen
 * 2. The location we suggest based on the outlook location
 * 3. The last location the user selected
 */
export const aggregatedLocationState = selector<string | undefined>({
  key: 'aggregatedLocationState',
  get: ({ get }) => {
    const selectedLocation = get(selectedLocationState);
    const suggestedLocation = get(suggestedLocationState);

    const lastSelectedLocation = localStorage.getItem('lastSelectedLocation') ?? undefined;

    return selectedLocation ?? suggestedLocation ?? lastSelectedLocation;
  },
});

/**
 * The externals invited to this meeting. (emails not ending in "lego.com")
 */
export const externalAttendeesState = selector<Office.EmailAddressDetails[]>({
  key: 'externalAttendeesState',
  get: ({ get }) => {
    const attendees = get(outlookRequiredAttendeesState);
    const externals = attendees.filter((a) => isEmailExternal(a.emailAddress));

    return externals;
  },
});

export const hostEmailState = atom<string | undefined>({
  key: 'hostEmailState',
  default: undefined,
});

export const isEmbeddingState = atom<boolean>({
  key: 'isEmbeddingState',
  default: false,
});

type Location =
  paths['/outlook-addin']['get']['responses']['200']['content']['application/json']['locations'];

export const locationsState = atom<Location | undefined>({
  key: 'locationsState',
  default: undefined,
});

export const vmsRecipientState = atom<string | undefined>({
  key: 'vmsRecipientState',
  default: undefined,
});

export const appointmentDatesState = atom<{ start: Date; end: Date }>({
  key: 'appointmentDates',
  default: {
    start: new Date(),
    end: new Date(),
  },
});

export const hasRecurrencePattern = atom<boolean>({
  key: 'hasRecurrencePattern',
  default: false,
});
