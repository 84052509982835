import { max } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  appointmentDatesState,
  attachedDetailsState,
  hasRecurrencePattern,
  hostEmailState,
  locationsState,
  outlookRequiredAttendeesState,
  selectedLocationState,
  suggestedLocationState,
  vmsRecipientState,
} from './atoms';
import { correctAllDayMeeting, isAllDayMeeting, longestCommonSubstring } from './helpers';
import { findVMSRecipient, getAllAttendees, mailboxItem } from './office-api';
import { loadAttachedVisitDetails, storeAttachedVisitDetails, stringifyDetails } from './storage';

type RecurringMeeting = {
  recurrenceType: string;
  recurrenceProperties: {
    interval: number;
    days: string[];
    firstDayOfWeek: string;
  };
};

/**
 * Synchronizes our application state with changes made in the outlook meeting window. This hook should only be called once in the root app.
 */
export const useOutlookData = (): void => {
  const [outlookLocations, setOutlookLocation] = useState<string | undefined>();
  const setOutlookAttendees = useSetRecoilState(outlookRequiredAttendeesState);
  const setSelectedLocation = useSetRecoilState(selectedLocationState);
  const setSuggestedLocation = useSetRecoilState(suggestedLocationState);
  const setHostEmail = useSetRecoilState(hostEmailState);
  const locations = useRecoilValue(locationsState);

  const [attachedDetails, setAttachedDetails] = useRecoilState(attachedDetailsState);

  const setVMSRecipient = useSetRecoilState(vmsRecipientState);
  const setDatesState = useSetRecoilState(appointmentDatesState);
  const setHasRecurrencePattern = useSetRecoilState(hasRecurrencePattern);

  const item = mailboxItem();

  const setDates = useCallback(
    (start: Date, end: Date) => {
      if (isAllDayMeeting(start, end)) {
        setDatesState(correctAllDayMeeting(start, end));

        return;
      }

      setDatesState({
        start,
        end,
      });
    },
    [setDatesState]
  );

  // Synchronizes outlook location, organizer, appointment time
  useEffect(() => {
    // Load host email into app state
    // If using someone else mailbox (on behalf) it will be that person mail
    // In case we want to use original sender (assistant) we can get it with Office.context.mailbox.userProfile.emailAddress
    item.organizer.getAsync({}, (res) => setHostEmail(res.value.emailAddress));

    // Load the initial location string into app state
    item.location.getAsync({}, (res) => setOutlookLocation(res.value));

    item.recurrence.getAsync({}, (res) =>
      setHasRecurrencePattern(Boolean(res.value.recurrenceProperties))
    );

    // Subscribe to any changes in location
    item.addHandlerAsync(
      Office.EventType.EnhancedLocationsChanged,
      (result: Office.EnhancedLocationsChangedEventArgs | null) => {
        if (!result?.enhancedLocations) {
          setOutlookLocation(undefined);

          return;
        }

        const enchancedLocations = result.enhancedLocations.map((l) => l.displayName).join('; ');

        setOutlookLocation(enchancedLocations);
      }
    );

    item.addHandlerAsync(
      Office.EventType.RecurrenceChanged,
      (event: { recurrence: RecurringMeeting | null }) => {
        setHasRecurrencePattern(Boolean(event.recurrence));
      }
    );

    item.addHandlerAsync(
      Office.EventType.AppointmentTimeChanged,
      (result: Office.AppointmentTimeChangedEventArgs) => {
        setDates(
          new Date(result.start as unknown as string),
          new Date(result.end as unknown as string)
        );
      }
    );
  }, [item, setOutlookLocation, setHostEmail, setDates, setHasRecurrencePattern]);

  // Synchronizes outlook attendees
  useEffect(() => {
    const updateAttendees = (): void => {
      getAllAttendees().then(setOutlookAttendees);
    };

    // Load initial state
    updateAttendees();

    // Subscribe to updates
    item.addHandlerAsync(Office.EventType.RecipientsChanged, updateAttendees);
  }, [setOutlookAttendees, item]);

  // Load existing VMS Recipient if we are editing event
  useEffect(() => {
    findVMSRecipient().then((x) => setVMSRecipient(x));
  }, [setVMSRecipient, item]);

  // Synchronizes attached details (what has already been saved on this item)
  useEffect(() => {
    loadAttachedVisitDetails().then((details) => {
      console.log('Stored details:', details);

      // Load the initial appointment time and override with the selected end date
      item.start.getAsync({}, (startRes) => {
        item.end.getAsync({}, (endRes) => {
          setDates(
            startRes.value,
            details?.accessEndDate
              ? max([endRes.value, new Date(details.accessEndDate)])
              : endRes.value
          );
        });
      });

      if (!details) {
        return;
      }

      setAttachedDetails(stringifyDetails(details));
      setSelectedLocation(details.locationId);
    });
  }, [item, setAttachedDetails, setSelectedLocation, setDates]);

  useEffect(() => {
    storeAttachedVisitDetails(attachedDetails);
  }, [attachedDetails]);

  // Update suggested location based on selected meeting room and available vms locations
  useEffect(() => {
    if (!outlookLocations || !locations) {
      setSuggestedLocation(undefined);

      return;
    }

    let bestMatchLength = 0;
    let bestMatchId: string | undefined;

    for (const location of locations) {
      const match = longestCommonSubstring(outlookLocations, location.name);

      if (match > bestMatchLength) {
        bestMatchLength = match;
        bestMatchId = location.id;
      }
    }

    // Only suggest location if we matched more than 3 characters
    if (bestMatchLength > 3) {
      setSuggestedLocation(bestMatchId);
    }
  }, [outlookLocations, locations, setSuggestedLocation]);
};
