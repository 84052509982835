// Sentry initialization should be imported first!
import './utils/sentry';
import { KlikProvider } from '@lego/klik-ui';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { App } from './App';
import { theme } from './utils/theme';

const client = new QueryClient();

const render = () =>
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <QueryClientProvider client={client}>
        <RecoilRoot>
          <KlikProvider cssVarsRoot=":root" includeFont={true} theme={theme}>
            <App />
          </KlikProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </React.StrictMode>
  );

/* Render application after Office initializes */
Office.initialize = () => {
  console.log('Office was initialized');

  render();
};
