import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_GITHUB_SHA) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: import.meta.env.MODE,
    debug: import.meta.env.MODE === 'development',

    release: import.meta.env.VITE_GITHUB_SHA,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: import.meta.env.MODE === 'prod' ? 0.3 : 1.0,

    attachStacktrace: true,

    tracePropagationTargets: [
      'localhost',
      '/^https://api.dev.vms.legogroup.io/',
      '/^https://api.prod.vms.legogroup.io/',
      '/^https://outlook.office.com/',
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

Office.onReady((info) => {
  if (info.host === Office.HostType.Outlook) {
    Office.context.mailbox.item?.organizer.getAsync({}, (res): void =>
      Sentry.setUser({
        name: res.value.displayName,
        email: res.value.emailAddress,
      })
    );
  }
});
