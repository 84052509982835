import { captureEvent } from '@sentry/react';
import { initialize, outlookAddinGet } from '@vms/common';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { externalAttendeesState, locationsState } from '../utils/atoms';
import { assembleClientInfo, isDevMode } from '../utils/helpers';
import { getAccessToken } from '../utils/office-api';
import { HasExternalsContent } from './HasExternalsContent';
import { Headline } from './Headline';
import { Toast } from './Toast';

const url = import.meta.env.VITE_GUEST_EXP_API_URL ?? 'https://localhost:3000/api';

export const AppContent: React.FC = () => {
  const externals = useRecoilValue(externalAttendeesState);
  const hasExternals = externals.length > 0;

  const [isReady, setIsReady] = useState(false);

  const [criticalError, setCriticalError] = useState<
    { message: string; title: string } | undefined
  >();

  // first load
  useEffect(() => {
    Office.onReady(async () => {
      console.log('Office is ready');

      try {
        const token = await getAccessToken();
        console.log('Access Token', token);

        initialize(url, { token });

        setIsReady(true);
      } catch (e) {
        console.log('Error during startup', e);

        const isSafari = /^(?:(?!chrome|chromium).)*safari/iu.test(navigator.userAgent);

        if (isSafari) {
          setCriticalError({
            title: 'Safari not supported',
            message:
              'The security and data privacy settings in Safari is preventing this addin from functioning. Please try again using Chrome or use the native outlook app in Windows or MacOS.',
          });
        } else {
          setCriticalError({
            title: 'Failed get access token',
            message:
              'The addin failed to access the office API. This might be an issue with privacy settings. Please try using a different browser or use the native outlook app in Windows or MacOS.',
          });
        }
      }
    });
  }, [setIsReady]);

  const setLocations = useSetRecoilState(locationsState);

  useQuery('locations', () => outlookAddinGet(assembleClientInfo()).then((d) => d.data), {
    enabled: isReady,
    onSuccess: (result) => {
      if (isDevMode) {
        setLocations(result.locations);

        return;
      }

      // Only show enabled locations to normal users
      setLocations(result.locations.filter((l) => l.enabled));
    },
    onError: (locationQueryError) => {
      console.log('Something went bad when trying to find locations', locationQueryError);

      if (locationQueryError && locationQueryError instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(locationQueryError.message);
        // eslint-disable-next-line no-console
        console.error(locationQueryError);

        captureEvent({
          message: locationQueryError.message,
          extra: {
            errorObject: locationQueryError,
          },
        });

        // show a better error message so the user know what to do
        // sometimes when the app is started, the error object will contain this message when trying to generate a token
        if (locationQueryError.message.startsWith('API is not supported in this platform.')) {
          setCriticalError({
            title: 'Old version of Outlook',
            message:
              "You're using an older version of Outlook, please reach out to Service Desk to upgrade.",
          });

          return;
        }

        setCriticalError({
          title: 'Out of service',
          message:
            'The visitor management system is currently out of service. Please try again later.',
        });
      }
    },
  });

  // Check available requirement set
  useEffect(() => {
    const is18Supported = Office.context.requirements.isSetSupported('Mailbox', '1.8');

    if (!is18Supported) {
      setCriticalError({
        title: 'Not supported',
        message:
          'Looks like your version of outlook does not support the features required by our add-in. Please update to the latest version of outlook and try again.',
      });
    }
  }, [setCriticalError]);

  if (criticalError) {
    return (
      <Toast title={criticalError.title} variant="error">
        {criticalError.message}
      </Toast>
    );
  }

  if (hasExternals) {
    return <HasExternalsContent />;
  }

  return <Headline>Please invite an external attendee to your meeting to get started...</Headline>;
};
